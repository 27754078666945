.welcome {
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
}
.welcome-position {
	font-family: "bold";
	font-size: 2.53rem;
	text-align: center;
	font-size: clamp(0.4rem, 2vw + 0.5rem, 2.53rem);
}
.welcome-title {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.welcome-light {
	background-color: var(--primary-bg-color);
	color: var(--second-bg-color);
}

.welcome-dark {
	background-color: var(--second-bg-color);
	color: var(--primary-bg-color);
}

.welcome-light a {
	color: var(--secundary-color);
}

.welcome-dark a {
	color: var(--primary-bg-color);
}

.welcome-dark svg {
	color: var(--primary-color);
}

.welcome-light svg {
	color: var(--primary-color);
}

.welcome-arrow {
	font-family: "light";
	text-align: center;
	position: absolute;
	bottom: 3rem;
}

.welcome-arrow-dark {
	color: var(--secundary-color);
}

.welcome-arrow-light {
	color: var(--secundary-color);
}

.btn-shine {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 12px 48px;
	color: #ffffff;
	background: linear-gradient(to right, #4d4d4d 0, white 10%, #4d4d4d 20%);
	background-position: 0;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: shine 5s infinite linear;
	animation-fill-mode: forwards;
	-webkit-text-size-adjust: none;
	font-weight: 600;
	font-size: 16px;
	text-decoration: none;
	white-space: nowrap;
}

@keyframes shine {
	0% {
		background-position: 0;
	}
	60% {
		background-position: 180px;
	}
	100% {
		background-position: 180px;
	}
}
