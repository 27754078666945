.nav-button {
	margin-right: 1.5rem;
	border-radius: 50%;
	height: 1.5rem;
	position: relative;
	width: 3rem;
	z-index: 0;
	content: " ";
}

.nav-button-left { 
	border-radius: 50%;
	width: 1.5rem;
	height: 1.5rem;
	position: absolute;
	margin-left: 0px;
	z-index: 0;
}

.nav-button-center { 
	position: absolute;
	margin-left: .75rem;
	width: 1.5rem;
	height: 1.5rem;
	z-index: 0;
}

.nav-button-right { 
	position: absolute;
	margin-left: 1.5rem;
	border-radius: 50%;
	width: 1.5rem;
	height: 1.5rem;
	z-index: 0;
}

.nav-button-dark {
	background-color: black;
}

.nav-button-light {
	background-color: gray;
}

.nav-button:hover {
	cursor: pointer;
}

.nav-button-circle {
	border-radius: 50%;
	height: 1.6rem;
	position: absolute;
	top: -0.1rem;
	width: 1.7rem;
	z-index: 1;
}

.nav-button-circle-dark {
	background-color: white;
	border: 0.1rem white solid;
	left: 0rem;
}

.nav-button-circle-light {
	background-color: white;
	border: 0.1rem gray solid;
	left: 1.3rem;
}
