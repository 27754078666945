@font-face {
	font-family: "light";
	src: url("./fonts/Poppins-Light.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "regular";
	src: url("./fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "black";
	src: url("./fonts/Poppins-Black.ttf") format("truetype");
}

@font-face {
	font-family: "bold";
	src: url("./fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "ebold";
	src: url("./fonts/Poppins-ExtraBold.ttf") format("truetype");
}
