.project {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-bottom: 6rem;
}

.project-column {
	width: 50%;
	padding: 1rem;
}

.project-img {
	position: relative;
	width: 70%;
	height: clamp(1rem, 13rem, 800px);
	min-width: 320px;
}

.img11 {
	position: absolute;
	margin-top: 0;
	left: 0;
}

.img21 {
	position: absolute;
	margin-top: 5rem;
	left: 60%;
}

.project-text {
	width: 30%;

	padding: 1rem;
}

.project-links {
	display: flex;
	align-items: center;
}

.project-button{
	font-family: "light";
	border: 0.1rem var(--primary-bg-color) solid;
	border-radius: .5rem;
	padding: 1rem;
	margin-right: 1rem;
	width: fit-content;
}

.project-link{
	font-family: "light";
}

.project-tech {
	align-items: center;
	display: flex;
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.project-tech svg {
	font-size: 2.5rem;
	margin-right: 3rem;
	color: var(--primary-color);
}

.project-tech-list {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.project-tech-item {
	margin-right: 1rem;
	font-family: "light";
	font-size: clamp(.8rem, 2vw + .2rem, 1rem);
	color: var(--primary-color);
}

@media (max-width: 800px) {
	.project-column {
		flex: 100%;
	}
}
