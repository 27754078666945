.header {
	display: flex;
	align-content: space-between; 
	align-items: center;
 
}
.header-wrapper{
	position: absolute;
	width: 100%;
	top: 0px;
	z-index: 1;
}
.header-dark {
	background-color: var(--second-bg-color);
	color: var(--primary-bg-color);
}

.header-light {
	background-color: var(--primary-bg-color);
	color: var(--second-bg-color);
}

.i{
	margin-top: 1rem;
}