.nav {
	width: 100%;
}

.nav-ul { 
	font-size: clamp(.7rem, 2vw + 0.5rem, 1.3rem);
 
	font-family: "light";
	display: flex;
	width: 100%;
	justify-content: center;
}

.nav-li {
	margin-left: .5rem;
	margin-right: .5rem;
	padding-left: .5rem;
	padding-right: .5rem;
	padding-bottom: 1rem;
	padding-top: 3rem;
}

.nav-li-dark {
	background-color: var(--second-bg-color);
	color: var(--primary-bg-color);
}

.nav-li:hover{
	background-color:var(--primary-color);

}

.nav-li-light {
	background-color: var(--primary-bg-color);
	color: var(--secundary-bg-color);
}

.nav-li:hover {
	cursor: pointer;
}

.nav-li-active {
	font-weight: bold;
	
}

.nav-li-active-dark {
	border-bottom: 0.1rem var(--primary-color) solid;
}

.nav-li-active-light {
	border-bottom: 0.2rem var(--primary-color) solid;
}
